.App {
  text-align: center;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-device-width: 800px) {
  html div header h1 {
    font-size: 12vw;
  }

  html div body div h2 {
    font-size: 7vw;
  }

  .App-header {
    background-color: #fdfaf6;
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: goldenrod;
  }

  .App-logo {
    height: 60vmin;
    pointer-events: none;
    margin-top: 5px;
  }
}

@media (min-device-width: 800px) {
  html div header h1 {
    font-size: 5vw;
  }

  html div body div h2 {
    font-size: 2vw;
  }

  html div body div h3 {
    font-size: 2vw;
  }

  .App-header {
    background-color: #fdfaf6;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: goldenrod;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 20px;
  }
}

body body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Wish-text-cont {
  max-width: 600px;
  margin-left: 10px;
  margin-right: 10px;
}

.Wish-text-cont-2 {
  max-width: 400px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 100px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* vietnamese */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Rep8ltA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3ROp8ltA.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
